import React from 'react'
import Layout from '../../components/Layout'
import { Link } from 'gatsby'
import * as styles from '../../assets/css/surveys.module.css'

const SurveyCasas = () => {

  return (
    <Layout>
      <section>
        <h1>Seaside Town Architect’s Survey</h1>
        <h2>Leo Casas</h2>
        <section>
          <p className={styles.bold}>When did you serve as Seaside Town Architect?</p>
          <p>I served as Town Architect from February 2, 2005 to February 1, 2009.</p>

          <p className={styles.bold}>What initially drew you to Seaside?</p>
          <p>Robert Davis appointed me Town Architect after an initial interview and my participation in the master planning charette that produced the most recent central square development plans which included Market buildings and Leon Krier’s tower.</p>

          <p className={styles.bold}>What do you consider your greatest accomplishments during your time as Town Architect?</p>
          <p>I believe my most important accomplishment was instilling a heightened sense of quality and respect for the classical and vernacular language of architecture within the infrastructure of the Urban Code. Seaside had gone without a Town Architect for several years prior to my appointment and the lack of quality in the buildings built during that period was palpable and unacceptable in a place like Seaside. My tenure, I would like to think, brought about a dramatic change that emphasized quality in aesthetics as well as the return to time honored methods of building.</p>

          <p className={styles.bold}>What were common challenges you found while working in Seaside?</p>
          <p>The single most challenging aspect of the role of Town Architect was the political component. As Town Architect, one is the lightning rod, as it were, for any controversy related to design within the town. The controversies highlighted the dysfunctional relationship between Town Founder and some of the homeowners. It was utterly surprising to learn that Robert Davis was vilified by some of the constituency while the rest of the world saw his work and the Town of Seaside as the catalyst for a paradigm shift in town making.</p>

          <p className={styles.bold}>How do you feel about Seaside today?</p>
          <p>Our work continues and will always be a work in progress. Like any village, town or city, it will never really be complete and will depend greatly on the good stewardship by both its citizens and the Town Architects yet to come.</p>

          <p className={styles.bold}>Do you consider yourself an Urbanist or New Urbanist? How do you feel Seaside fits in with modern urban philosophy?</p>
          <p>By training, I am an architect and by extension an urbanist. I believe one cannot, indeed ought not to practice architecture, without having a complete understanding and appreciation of the dialectic between architecture and urbanism. My training and practice has always prepared me for both. Having lived, studied and taught in Rome by way of the Notre Dame Rome Studies Program, I can say prepared me for my tenure in Seaside as I have been able to apply lessons learned in Rome to issues in Seaside.</p>

          <p className={styles.bold}>Can you give a brief summary of what you are doing today, including any recent projects, awards, or positions?</p>
          <p>My practice continues in Seaside with a mix of civic, residential and commercial projects. I recently received the 2010 Palladio Award for a home I designed located in the town of Seaside. I am often invited to speak about the Town of Seaside, it architectural and urban significance in the world at large. The next venue where I will speak will be the Seaside at 30, conference at Notre Dame followed by the “the Heritage of Iconic Planned Communities; The Challenges of Change” at SACRPH’s 14th National Conference on Planning History, which will take place in Baltimore, Maryland, November 17–20 2011.</p>
          <hr />
        </section>
        <div className={styles.center}><Link to='/essays/town-architects'>Back to Town Architects</Link></div>
      </section>
    </Layout>
  )
}

export default SurveyCasas
